import { useState, useEffect } from "react";
import * as airConditionerAPI from "../lib/airConditioner";
import * as deviceAPI from "../lib/device";

function useAirConditioner(deviceID: string) {
  const [
    airConditioner,
    setAirConditioner,
  ] = useState<airConditionerAPI.AirConditionerProps>({
    power: false,
    mode: 0,
    temperature: 0,
    lowTemperature: 0,
    highTemperature: 0,
    coolTemperature: 0,
    heatTemperature: 0,
    auto: false,
  });

  const getAirConditioner = () => {
    airConditionerAPI.getAirConditioner(deviceID).then((resolvedData) => {
      setAirConditioner(resolvedData);
    });
  };

  const patchAirConditioner = (
    propertyName: string,
    propertyValue: number | boolean
  ) => {
    deviceAPI
      .patchAirConditioner(deviceID, propertyName, propertyValue)
      .then(() => {
        setTimeout(getAirConditioner, 1000);
        // getAirConditioner();
      });
  };

  useEffect(() => {
    getAirConditioner();

    const interval = setInterval(getAirConditioner, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return { airConditioner, getAirConditioner, patchAirConditioner };
}

export default useAirConditioner;
