import React from "react";
import styled from "styled-components";
import TemperatureLineChart from "./ChartGroup/TemperatureLineChart";
import HumidityLineChart from "./ChartGroup/HumidityLineChart";
import CO2LineChart from "./ChartGroup/CO2LineChart";
import useTemperatureHumidityChart from "../../hooks/useTemperatureHumidityChart";
import useCO2Chart from "../../hooks/useCO2Chart";

const ChartGroupBlock = styled.div`
  width: 100%;
  height: 100%;
`;

const RightDiv = styled.div`
  width: 100%;
  height: 33.33%;
`;

interface ChartGroupProps {
  smartFarmID: number;
}

function ChartGroup({ smartFarmID }: ChartGroupProps) {
  const temperatureHumidity = useTemperatureHumidityChart(smartFarmID);
  const co2 = useCO2Chart(smartFarmID);

  return (
    <ChartGroupBlock>
      <RightDiv>
        <TemperatureLineChart
          smartFarmID={smartFarmID}
          temperatureData={temperatureHumidity.map((element) => {
            return { time: element.time, value: element.temperature };
          })}
        />
      </RightDiv>
      <RightDiv>
        <HumidityLineChart
          smartFarmID={smartFarmID}
          humidityData={temperatureHumidity.map((element) => {
            return { time: element.time, value: element.humidity };
          })}
        />
      </RightDiv>
      <RightDiv>
        <CO2LineChart
          smartFarmID={smartFarmID}
          co2Data={co2.map((element) => {
            return { time: element.time, value: element.co2 };
          })}
        />
      </RightDiv>
    </ChartGroupBlock>
  );
}

export default ChartGroup;
