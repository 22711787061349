import React from "react";
import styled from "styled-components";
import { Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";

const AppBlock = styled.div`
  width: 100%;
  height: 100%;
  & * {
    box-sizing: border-box;
  }
`;

function App() {
  return (
    <AppBlock>
      <Route path="/" component={LoginPage} exact />
      <Route path="/dashboard" component={DashboardPage} />
    </AppBlock>
  );
}

export default App;
