import React from "react";
import styled from "styled-components";
import TableData from "../../../commons/TableData";
import useTemperatureHumidityRecent from "../../../hooks/useTemperatureHumidityRecent";
import LineChart, { DataProps } from "../../../commons/LineChart";

const TemperatureLineChartBlock = styled.div`
  width: 98%;
  height: 80%;
`;

interface TemperatureLineChartProps {
  smartFarmID: number;
  temperatureData?: DataProps[];
}

function TemperatureLineChart({
  smartFarmID,
  temperatureData,
}: TemperatureLineChartProps) {
  const recent = useTemperatureHumidityRecent(smartFarmID);

  return (
    <TableData
      title={"온도 차트 (현재 : " + recent.temperature.toFixed(2) + " ℃)"}
    >
      <TemperatureLineChartBlock>
        <LineChart data={temperatureData} />
      </TemperatureLineChartBlock>
    </TableData>
  );
}

export default TemperatureLineChart;
