export type DeviceProps = {
  name: string;
  id: string;
};

export const nutrientControllers: DeviceProps[] = [
  { name: "1번 양액 제어기", id: "NAB300-0005" },
];

export const cultivators: DeviceProps[] = [
  { name: "1번 재배기", id: "OA100-0025" },
  { name: "2번 재배기", id: "OA100-0026" },
  { name: "3번 재배기", id: "OA100-0027" },
];

export const envs: DeviceProps[] = [
  { name: "1번 환경 측정기", id: "ENV100-0027" },
];

export const acs: DeviceProps[] = [];

export const smartFarmID = 4;
