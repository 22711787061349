import React from "react";
import styled from "styled-components";
import Box from "./Box";

interface TableDataComponentBlockProps {
  hover?: boolean;
}

const TableDataComponentBlock = styled.div<TableDataComponentBlockProps>`
  width: 100%;
  height: 100%;
  padding: 0.5rem;

  &: ${(props) => (props.hover ? "hover" : "")} {
    background-color: #ffffc5;
  }
`;

const Title = styled.div`
  width: 100%;
  padding: 0.5rem;
  font-weight: bold;
`;

interface TableDataComponentProps {
  children?: React.ReactNode;
  title?: string;
  hover?: boolean;
  onClick?: () => void;
}

function TableDataComponent({
  children,
  title = "title",
  hover = false,
  onClick,
}: TableDataComponentProps) {
  return (
    <TableDataComponentBlock hover={hover} onClick={onClick}>
      <Box width={100} height={100}>
        <Title>{title}</Title>
        {children}
      </Box>
    </TableDataComponentBlock>
  );
}

export default TableDataComponent;
