import IoTAPI from "./api/IoTAPI";

const get = (url: string) => IoTAPI.get("/smartfarmtemperaturehumidity" + url);

export type TemperatureHumidityProps = {
  time: string;
  temperature: number;
  humidity: number;
};

export const getChart = async (
  smartfarmID: number,
  fromTime: string,
  toTime: string
) => {
  try {
    const response = await get(
      "/chart?smartFarmID=" +
        smartfarmID +
        "&fromTime=" +
        fromTime +
        "&toTime=" +
        toTime
    );

    if (response.status !== 200) {
      return [] as TemperatureHumidityProps[];
    } else {
      const result: TemperatureHumidityProps[] = response.data;

      return result;
    }
  } catch (e) {
    return [] as TemperatureHumidityProps[];
  }
};

export const getRecent = async (smartfarmID: number) => {
  try {
    const response = await get("/recent?smartFarmID=" + smartfarmID);

    if (response.status !== 200) {
      return {
        time: "error",
        temperature: 0,
        humidity: 0,
      } as TemperatureHumidityProps;
    } else {
      return response.data as TemperatureHumidityProps;
    }
  } catch (e) {
    return {
      time: "error",
      temperature: 0,
      humidity: 0,
    } as TemperatureHumidityProps;
  }
};
