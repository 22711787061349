import IoTAPI from "./api/IoTAPI";

export type AirConditionerProps = {
  power: boolean;
  mode: number;
  temperature: number;
  lowTemperature: number;
  highTemperature: number;
  coolTemperature: number;
  heatTemperature: number;
  auto: boolean;
};

const get = (parameter: string) => IoTAPI.get("/airconditioner/" + parameter);

export const getAirConditioner = async (deviceID: string) => {
  try {
    const response = await get(deviceID + "/airconditioner");

    if (response.status !== 200) {
      return {
        power: false,
        mode: 0,
        temperature: 0,
        lowTemperature: 0,
        highTemperature: 0,
        coolTemperature: 0,
        heatTemperature: 0,
        auto: false,
      };
    } else {
      const {
        power,
        mode,
        temperature,
        lowTemperature,
        highTemperature,
        coolTemperature,
        heatTemperature,
        auto,
      } = response.data;

      return {
        power: power,
        mode: mode,
        temperature: temperature,
        lowTemperature: lowTemperature,
        highTemperature: highTemperature,
        coolTemperature: coolTemperature,
        heatTemperature: heatTemperature,
        auto: auto,
      };
    }
  } catch (e) {
    return {
      power: false,
      mode: 0,
      temperature: 0,
      lowTemperature: 0,
      highTemperature: 0,
      coolTemperature: 0,
      heatTemperature: 0,
      auto: false,
    };
  }
};
