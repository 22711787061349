import React, { useState, Suspense } from "react";
import styled from "styled-components";
import loadable from "@loadable/component";
import TableData from "../../commons/TableData";
import useConnections from "../../hooks/useConnections";
import DeviceStatus from "./DeviceStatusComponent/DeviceStatus";
import { DeviceProps } from "../../data/deviceIDs";

const Contents = styled.div`
  width: 100%;
  height: 80%;
  overflow: auto;
`;

const fallback = () => <div>loading...</div>;

const DeviceStatusModal = loadable(
  () => import("./DeviceStatusComponent/DeviceStatusModal")
);

interface DeviceStatusComponentProps {
  deviceIDs?: DeviceProps[];
}

function DeviceStatusComponent({ deviceIDs = [] }: DeviceStatusComponentProps) {
  const [modal, setModal] = useState(false);
  const connections = useConnections(deviceIDs);
  const { connected, disconnected } = connections;

  const handleModal = () => {
    const newState = !modal;
    if (!modal) {
      // NOTE: 왜 !modal 이냐
      window.scrollTo(0, 0);
    }
    setModal(newState);
  };

  return (
    <>
      <TableData title="디바이스 연결 상태" hover={true} onClick={handleModal}>
        <Contents>
          {/* eslint-disable-next-line */}
          {disconnected.map((connection) => (
            <DeviceStatus connection={connection.connection}>
              {connection.name}
            </DeviceStatus>
          ))}
          {/* eslint-disable-next-line */}
          {connected.map((connection) => (
            <DeviceStatus connection={connection.connection}>
              {connection.name}
            </DeviceStatus>
          ))}
        </Contents>
      </TableData>
      {modal && (
        <Suspense fallback={fallback}>
          <DeviceStatusModal deviceIDs={deviceIDs} onClose={handleModal} />
        </Suspense>
      )}
    </>
  );
}

export default DeviceStatusComponent;
