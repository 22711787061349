import { useContext } from "react";
import { UserStateContext, UserDispatchContext } from "../contexts/UserContext";

export function useUserState() {
  const state = useContext(UserStateContext);
  if (!state) throw new Error("UserContextProvider not found");
  return state;
}

export function useUserDispatch() {
  const state = useContext(UserDispatchContext);
  if (!state) throw new Error("UserContextProvider not found");
  return state;
}
