import { useState, useEffect } from "react";
import * as co2API from "../lib/smartFarmCo2";

function useCO2Recent(smartFarmID: number) {
  const [co2Recent, setCo2Recent] = useState<co2API.Co2Props>({
    time: "default",
    co2: 0,
  });

  const getRecentData = () => {
    co2API.getRecent(smartFarmID).then((resolvedData) => {
      setCo2Recent(resolvedData);
    });
  };

  useEffect(() => {
    getRecentData();

    const interval = setInterval(getRecentData, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return co2Recent;
}

export default useCO2Recent;
