import { useState, useEffect } from "react";
import * as temperatureHumidityAPI from "../lib/smartFarmTemperatureHumidity";

function useTemperatureHumidityChart(smartFarmID: number) {
  const [temperatureHumidity, setTemperatureHumidity] = useState<
    temperatureHumidityAPI.TemperatureHumidityProps[]
  >([]);

  const getChartData = () => {
    let now = new Date();
    let yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);

    temperatureHumidityAPI
      .getChart(smartFarmID, yesterday.toISOString(), now.toISOString())
      .then((resolvedData) => {
        const data = resolvedData;
        setTemperatureHumidity(data);
      });
  };

  useEffect(() => {
    getChartData();

    const interval = setInterval(getChartData, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return temperatureHumidity;
}

export default useTemperatureHumidityChart;
