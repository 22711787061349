import React from "react";
import styled from "styled-components";

interface ButtonBlockProps {
  connection?: boolean;
  power?: boolean;
  width?: number;
  height?: number;
  fontSize?: number;
}

const ButtonBlock = styled.button<ButtonBlockProps>`
  width: ${(props) => props.width + "%"};
  height: ${(props) => props.height + "%"};
  border-radius: 4px;
  text-align: center;
  color: ${(props) =>
    props.connection && !props.disabled ? "white" : "#909090"};
  background-color: ${(props) => {
    if (props.connection && !props.disabled)
      return props.power ? "red" : "#368aff";
    else return "#EAEAEA";
  }};
  border: 0;
  outline: 0;
  padding: 0.3rem;
  font-size: ${(props) => props.fontSize + "rem"};
  cursor: pointer;
`;

interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  connection?: boolean;
  power?: boolean;
  width?: number;
  height?: number;
  fontSize?: number;
  onClick?: () => void;
  disabled?: boolean;
}

function Button({
  children,
  className,
  connection = true,
  power = false,
  width = -1,
  height = -1,
  fontSize = 1,
  onClick,
  disabled = false,
}: ButtonProps) {
  return (
    <ButtonBlock
      className={className}
      connection={connection}
      power={power}
      width={width}
      height={height}
      fontSize={fontSize}
      onClick={
        connection ? onClick : () => alert("디바이스 연결 상태를 확인해주세요.")
      }
      disabled={disabled}
    >
      {children}
    </ButtonBlock>
  );
}

export default Button;
