import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { useCookies } from "react-cookie";
import * as logInAPI from "../../lib/api/loginAPI";
import Button from "../../commons/Button";
import { useUserState, useUserDispatch } from "../../hooks/useLogin";
import { encryption, decryption } from "../../lib/encryption/encryption";
import { isUserInfoExist } from "../../lib/checkUserInfo";
import logo from "../../images/main-logo.png";

const LoginFormComponentBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoginFormComponentChildren = styled.div`
  width: 50%;
  padding: 1rem 0 1rem 0;
`;

const LogoDiv = styled(LoginFormComponentChildren)``;

const SubTitleDiv = styled(LoginFormComponentChildren)`
  text-align: center;
  color: #b0b0b0;
`;

const FormDiv = styled(LoginFormComponentChildren)``;

const StyledImg = styled.img`
  width: 100%;
`;

const StyledBlock = styled.div`
  position: relative;
  margin-top: 2rem;
`;

const FocusBorder = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #0399ff;
  transition: 0.4s;
`;

const StyledInput = styled.input`
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 2px solid #d9d9d9;
  padding-bottom: 0.7rem;
  &:focus ~ ${FocusBorder} {
    width: 100%;
  }
`;

const CheckBox = styled.input`
  margin-top: 1rem;
`;

const StyledSpan = styled.span`
  font-size: 0.9rem;
  margin-left: 0.3rem;
`;

const MoveToCenter = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  width: 6rem;
  height: 2rem;
  align-items: center;
  font-size: 1rem;
  background-color: #002342;
`;

function LoginFormComponent(history: RouteComponentProps) {
  // NOTE: 에러도 넣어야 될 듯 하다.
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [autoSave, setAutoSave] = useState(false);

  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const user = useUserState();
  const dispatch = useUserDispatch();

  const [cookie, setCookie, removeCookie] = useCookies(["user"]);

  const saveUserInfo = (auto: boolean, id: string, password: string) => {
    const userInfo = {
      id: id,
      password: password,
    };

    const encrytedItemName = encryption("user");
    const encryptedUserInfo = encryption(JSON.stringify(userInfo));

    sessionStorage.setItem(encrytedItemName, encryptedUserInfo);

    if (auto) {
      // secure(boolean): HTTPS로만 가능.
      // NOTE: 빌드 시에는 domain 값이 필요할 수 있음.
      if (isUserInfoExist(cookie) === -1)
        setCookie(encrytedItemName, encryptedUserInfo, { path: "/" });
    }
  };

  const logIn = async (id: string, password: string) => {
    try {
      const response = await logInAPI.logIn(id, password).then();

      if (response.status === 200) {
        const result = {
          id: id,
          password: password,
        };

        dispatch({
          type: "LOGIN_SUCCESS",
          user: result,
        });

        return true;
      }
    } catch (e) {
      dispatch({
        type: "LOGIN_FAILURE",
      });
      return false;
    }
  };

  useEffect(() => {
    // cookie.user 값이 있다면 (=> ID, 비밀번호 저장 체크됨.)
    const index = isUserInfoExist(cookie);
    if (index !== -1) {
      const decryptedUserInfo = decryption(Object.values(cookie)[index]); // {id, password}
      const parsedUserInfo = JSON.parse(decryptedUserInfo);
      setId(parsedUserInfo.id);
      setPassword(parsedUserInfo.password);
      setLoading(false);
      setAutoSave(true);
    }

    // 로그인되있는 상태
    if (isUserInfoExist(sessionStorage) !== -1) {
      alert("로그인 됨.");
      history.history.push("/dashboard");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user.logInStatus) history.history.push("/dashboard");
    else if (user.error) alert("로그인에 실패하였습니다.");
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (!loading && !autoSave) {
      const index = isUserInfoExist(cookie);
      const user = Object.keys(cookie)[index];
      removeCookie(user, { path: "/" });
    }

    // eslint-disable-next-line
  }, [autoSave]);

  const onIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setId(e.target.value);
  };

  const onPwChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onAutoSaveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAutoSave(e.target.checked);
  };

  const handleLogIn = async (e: React.FormEvent) => {
    setDisabled(true);
    e.preventDefault();
    await logIn(id, password).then((status) => {
      if (status) {
        saveUserInfo(autoSave, id, password);
      }
    });
    setDisabled(false);
  };

  return (
    <LoginFormComponentBlock>
      <LogoDiv>
        <StyledImg src={logo} alt="logo" />
      </LogoDiv>
      <SubTitleDiv>IoT 스테이션 입장을 위해 로그인이 필요합니다.</SubTitleDiv>
      <FormDiv>
        <form onSubmit={handleLogIn}>
          <StyledBlock>
            <StyledInput
              type="text"
              placeholder="Username"
              value={id}
              onChange={onIdChange}
            />
            <FocusBorder />
          </StyledBlock>
          <StyledBlock>
            <StyledInput
              type="password"
              placeholder="Password"
              value={password}
              onChange={onPwChange}
            />
            <FocusBorder />
          </StyledBlock>
          <CheckBox
            type="checkbox"
            checked={autoSave}
            onChange={onAutoSaveChange}
          />
          <StyledSpan>ID, 비밀번호 저장</StyledSpan>
          <MoveToCenter>
            <StyledButton connection={true} disabled={disabled}>
              로그인
            </StyledButton>
          </MoveToCenter>
        </form>
      </FormDiv>
    </LoginFormComponentBlock>
  );
}

export default withRouter(LoginFormComponent);
