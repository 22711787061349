import IoTAPI from "./api/IoTAPI";

const get = (url: string) => IoTAPI.get("/smartfarmco2" + url);

export type Co2Props = {
  time: string;
  co2: number;
};

export const getChart = async (
  smartfarmID: number,
  fromTime: string,
  toTime: string
) => {
  try {
    const response = await get(
      "/chart?smartFarmID=" +
        smartfarmID +
        "&fromTime=" +
        fromTime +
        "&toTime=" +
        toTime
    );

    if (response.status !== 200) {
      return [] as Co2Props[];
    } else {
      const result: Co2Props[] = response.data;

      return result;
    }
  } catch (e) {
    return [] as Co2Props[];
  }
};

export const getRecent = async (smartfarmID: number) => {
  try {
    const response = await get("/recent?smartFarmID=" + smartfarmID);

    if (response.status !== 200) {
      return {
        time: "error",
        co2: 0,
      } as Co2Props;
    } else {
      return response.data as Co2Props;
    }
  } catch (e) {
    return {
      time: "error",
      co2: 0,
    } as Co2Props;
  }
};
