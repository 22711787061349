import React, { createContext, Dispatch, useReducer } from "react";

type LogInStatus = {
  logInStatus: boolean;
  error: boolean;
  id: string;
  password: string;
};

type User = {
  id: string;
  password: string;
};

export const UserStateContext = createContext<LogInStatus | undefined>(
  undefined
);

type Action = { type: "LOGIN_SUCCESS"; user: User } | { type: "LOGIN_FAILURE" };

type UserDispatch = Dispatch<Action>;

export const UserDispatchContext = createContext<UserDispatch | undefined>(
  undefined
);

function userReducer(state: User, action: Action): LogInStatus {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        logInStatus: true,
        error: false,
        ...action.user,
      };
    case "LOGIN_FAILURE":
      return {
        logInStatus: false,
        error: true,
        id: "",
        password: "",
      };
    default:
      return {
        logInStatus: false,
        error: false,
        ...state,
      };
  }
}

export function UserContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [user, dispatch] = useReducer(userReducer, {
    logInStatus: false,
    error: false,
    id: "",
    password: "",
  });

  return (
    <UserDispatchContext.Provider value={dispatch}>
      <UserStateContext.Provider value={user}>
        {children}
      </UserStateContext.Provider>
    </UserDispatchContext.Provider>
  );
}
