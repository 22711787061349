import crypto from "crypto";

// Must be 256 bits (32 characters)
const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY as string;

// always 16
const ivLength = 16;

export function encryption(text: string) {
  const iv = crypto.randomBytes(ivLength);
  const cipher = crypto.createCipheriv("aes-256-cbc", encryptionKey, iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);

  const result = iv.toString("hex") + ":" + encrypted.toString("hex");
  //   console.log("encryption : " + result);
  return result;
}

export function decryption(text: string) {
  const textParts = text.split(":");
  // FIXME: textParts.shift()의 반환 값의 length가 홀수개일 경우 invalid hex string 문제가 발생
  if (textParts[0].length % 2 === 1) {
    document.cookie =
      textParts[0] + "=; expires=Thu, 01 Jan 1999 00:00:10 GMT;";
    // NOTE: 이 밑의 코드는 약간 임시방편
    return "invalid hex string";
  }
  const iv = Buffer.from(textParts.shift() as string, "hex");
  if (iv.length === 0) return "iv.length is 0"; // NOTE: invalid iv length is 0(임시 방편)
  const encryptedText = Buffer.from(textParts.join(":"), "hex");
  const decipher = crypto.createDecipheriv("aes-256-cbc", encryptionKey, iv);
  let decrypted = decipher.update(encryptedText);

  decrypted = Buffer.concat([decrypted, decipher.final()]);

  const result = decrypted.toString();
  //   console.log("decryption : " + result);
  return result;
}
