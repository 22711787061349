import React from "react";
import styled from "styled-components";
import { HorizontalBar } from "react-chartjs-2";

const HorizontalBarChartBlock = styled.div`
  height: 88%;
  padding-right: 0.5rem;
`;

type WaterLevelProps = {
  name: string;
  waterLevel: number;
};

interface HorizontalBarChartProps {
  chartData?: WaterLevelProps[];
  width?: number;
  height?: number;
  barPercentage?: number;
}

function HorizontalBarChart({
  chartData = [],
  width,
  height,
  barPercentage = 0.4,
}: HorizontalBarChartProps) {
  const getMaxValue = (dataParameter: number[]) => {
    let tmp: number[] = dataParameter;

    if (tmp.some((data) => data > 100)) {
      tmp.sort((a, b) => b - a);
      return tmp[0];
    }
    // NOTE: 농도 최댓값
    else if (tmp.some((data) => data > 3)) return 100;
    // NOTE: 수위 최댓값
    else return 3;
  };

  const getBackgroundColors = (dataParameter: number[], max: number) => {
    return dataParameter.map((data) => {
      if (max / 3 >= data) return "#FF4848";
      else if ((max / 3) * 2 >= data && data > max / 3) return "#FFDD73";
      else return "#5CD1E5";
    });
  };
  const names = chartData.map((element) => element.name);
  const waterLevels = chartData.map((element) => element.waterLevel);

  const max = getMaxValue(waterLevels);

  const data = {
    labels: names,
    datasets: [
      {
        label: "data",
        backgroundColor: getBackgroundColors(waterLevels, max),
        barPercentage: barPercentage,
        data: waterLevels,
      },
      {
        label: "background",
        backgroundColor: "rgba(234, 234, 234,1)",
        barPercentage: barPercentage,
        data: waterLevels.map((waterLevel) => max - waterLevel),
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          stacked: true,
          display: false,
        },
      ],
      yAxes: [
        {
          display: true,
          stacked: true,
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
  };

  return (
    <HorizontalBarChartBlock>
      <HorizontalBar
        data={data}
        width={width}
        height={height}
        options={options}
      />
    </HorizontalBarChartBlock>
  );
}

export default HorizontalBarChart;
