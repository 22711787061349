import { useEffect } from "react";
import { Chart, ChartOptions } from "chart.js";
import { Line } from "react-chartjs-2";

export interface DataProps {
  time: string;
  value: number;
}

interface LinearChartProps {
  title?: string;
  data?: DataProps[];
  borderColor?: string;
}

function LinearChart({
  title = "",
  data = [],
  borderColor = "blue",
}: LinearChartProps) {
  const chartData = {
    // labels: data.map((element) =>
    //   new Date(element.time).toLocaleString("ko-KR", { timeZone: "Asia/Seoul" })
    // ),
    datasets: [
      {
        label: title,
        data: data.map((element) => {
          return { x: new Date(element.time), y: element.value.toFixed(2) };
        }),
        borderColor: borderColor,
        borderWidth: 1,
        pointRadius: 0,
        showLine: true,
      },
    ],
  };

  const options: ChartOptions = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: "hour",
            tooltipFormat: "YYYY년 MM월 DD일 HH:mm:SS",
          },
        },
      ],
    },
    tooltips: {
      mode: "index",
      intersect: false,
    },
    legend: {
      display: false,
    },
    animation: {
      duration: 0,
    },
    showLines: false,
  };

  useEffect(() => {
    const originalLineDraw = Chart.controllers.line.prototype.draw;
    Chart.helpers.extend(Chart.controllers.line.prototype, {
      draw: function () {
        originalLineDraw.apply(this, arguments);

        if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
          const activePoint = this.chart.tooltip._active[0];
          const ctx = this.chart.ctx;
          const x = activePoint.tooltipPosition().x;
          const topY = this.chart.scales["y-axis-0"].top;
          const bottomY = this.chart.scales["y-axis-0"].bottom;

          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 2;
          ctx.strokeStyle = "#FF0000";
          ctx.stroke();
          ctx.restore();
        }
      },
    });
  }, []);

  return <Line data={chartData} options={options} />;
}

export default LinearChart;
