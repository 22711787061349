import IoTAPI from "./api/IoTAPI";

const get = (deviceID: string, waterLevelName: number) =>
  IoTAPI.get("/waterlevel/" + deviceID + "/waterlevel-" + waterLevelName);

export const getWaterLevel = async (
  deviceID: string,
  waterLevelName: number
) => {
  try {
    const response = await get(deviceID, waterLevelName);

    if (response.status !== 200) {
      return -1;
    } else {
      return response.data.state;
    }
  } catch (e) {
    return -1;
  }
};
