import React, { useState, Suspense } from "react";
import styled from "styled-components";
import loadable from "@loadable/component";
import useAirConditioner from "../../../hooks/useAirConditioner";
import { DeviceProps } from "../../../data/deviceIDs";
import cool from "../../../images/cool.png";
import heat from "../../../images/heat.png";

const AirConditionerStatusBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  &: hover {
    background-color: #ffffc5;
  }
`;

const DeviceName = styled.div`
  padding-right: 1rem;
`;

const StatusGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Power = styled.div`
  padding-right: 0.5rem;
`;

const Mode = styled.img`
  width: 30%;
  height: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const CurrentTemperature = styled.div`
  padding-left: 0.5rem;
`;

const fallback = () => <div>loading...</div>;

const AirConditionerModal = loadable(() => import("./AirConditionerModal"));

interface AirConditionerStatusProps {
  deviceID: DeviceProps;
}

function AirConditionerStatus({ deviceID }: AirConditionerStatusProps) {
  const [modal, setModal] = useState(false);
  const { airConditioner } = useAirConditioner(deviceID.id);

  const handleModal = () => {
    const newState = !modal;
    setModal(newState);
  };

  return (
    <>
      <AirConditionerStatusBlock onClick={handleModal}>
        <DeviceName>{deviceID.name}</DeviceName>
        <StatusGroup>
          <Power>{airConditioner.power ? "켜짐" : "꺼짐"}</Power>
          <Mode src={airConditioner.mode === 0 ? cool : heat} />
          <CurrentTemperature>
            {airConditioner.temperature} ℃
          </CurrentTemperature>
        </StatusGroup>
      </AirConditionerStatusBlock>
      {modal && (
        <Suspense fallback={fallback}>
          <AirConditionerModal
            deviceID={deviceID}
            airConditioner={airConditioner}
            onClose={handleModal}
          />
        </Suspense>
      )}
    </>
  );
}

export default AirConditionerStatus;
