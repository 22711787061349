import React from "react";
import styled from "styled-components";

interface BoxProps {
  children?: React.ReactNode;
  className?: string;
  width?: number;
  height?: number;
  boxShadowLength?: number;
  boxShadowColor?: string;
}

const BoxBlock = styled.div<BoxProps>`
  width: ${(props) => (props.width ? props.width + "%" : "auto")};
  height: ${(props) => (props.height ? props.height + "%" : "auto")};
  border-radius: 4px;
  border: 1px solid white;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 0
    ${(props) => props.boxShadowLength + "px " + props.boxShadowColor};
`;

function Box({
  children,
  className,
  width,
  height,
  boxShadowLength = 3,
  boxShadowColor = "gray",
}: BoxProps) {
  return (
    <BoxBlock
      className={className}
      width={width}
      height={height}
      boxShadowLength={boxShadowLength}
      boxShadowColor={boxShadowColor}
    >
      {children}
    </BoxBlock>
  );
}

export default Box;
