import React, { useState, Suspense } from "react";
import styled from "styled-components";
import loadable from "@loadable/component";
import TableData from "../../commons/TableData";
import HorizontalBarChart from "./NutrientControllerComponent/HorizontalBarChart";
import { DeviceProps } from "../../data/deviceIDs";
import useWaterLevels from "../../hooks/useWaterLevels";

const Contents = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const fallback = () => <div>loading...</div>;

const NutrientControllerModal = loadable(
  () => import("./NutrientControllerComponent/NutrientControllerModal")
);

interface NutrientControllerComponentProps {
  deviceIDs?: DeviceProps[];
}

function NutrientControllerComponent({
  deviceIDs = [],
}: NutrientControllerComponentProps) {
  const [modal, setModal] = useState(false);
  const waterLevels = useWaterLevels(deviceIDs);

  const handleModal = () => {
    const newState = !modal;
    setModal(newState);
  };

  return (
    <>
      <TableData title="양액 제어기" hover={true} onClick={handleModal}>
        <Contents>
          <HorizontalBarChart chartData={waterLevels} />
        </Contents>
      </TableData>
      {modal && (
        <Suspense fallback={fallback}>
          <NutrientControllerModal
            deviceIDs={deviceIDs}
            onClose={handleModal}
          />
        </Suspense>
      )}
    </>
  );
}

export default NutrientControllerComponent;
