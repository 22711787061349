import { useState, useEffect } from "react";
import * as temperatureHumidityAPI from "../lib/smartFarmTemperatureHumidity";

function useTemperatureHumidityRecent(smartFarmID: number) {
  const [
    temperatureHumidityRecent,
    setTemperatureHumidityRecent,
  ] = useState<temperatureHumidityAPI.TemperatureHumidityProps>({
    time: "default",
    temperature: 0,
    humidity: 0,
  });

  const getRecentData = () => {
    temperatureHumidityAPI.getRecent(smartFarmID).then((resolvedData) => {
      setTemperatureHumidityRecent(resolvedData);
    });
  };

  useEffect(() => {
    getRecentData();

    const interval = setInterval(getRecentData, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return temperatureHumidityRecent;
}

export default useTemperatureHumidityRecent;
