import React from "react";
import styled from "styled-components";
import TableData from "../../../commons/TableData";
import useTemperatureHumidityRecent from "../../../hooks/useTemperatureHumidityRecent";
import LineChart, { DataProps } from "../../../commons/LineChart";

const HumidityLineChartBlock = styled.div`
  width: 98%;
  height: 80%;
`;

interface HumidityLineChartProps {
  smartFarmID: number;
  humidityData?: DataProps[];
}

function HumidityLineChart({
  smartFarmID,
  humidityData,
}: HumidityLineChartProps) {
  const recent = useTemperatureHumidityRecent(smartFarmID);

  return (
    <TableData
      title={"습도 차트 (현재 : " + recent.humidity.toFixed(2) + " %)"}
    >
      <HumidityLineChartBlock>
        <LineChart data={humidityData} />
      </HumidityLineChartBlock>
    </TableData>
  );
}

export default HumidityLineChart;
