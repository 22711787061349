import React from "react";
import styled from "styled-components";
import TableData from "../../../commons/TableData";
import useCO2Recent from "../../../hooks/useCO2Recent";
import LineChart, { DataProps } from "../../../commons/LineChart";

const CO2LineChartBlock = styled.div`
  width: 98%;
  height: 80%;
`;

interface CO2LineChartProps {
  smartFarmID: number;
  co2Data?: DataProps[];
}

function CO2LineChart({ smartFarmID, co2Data }: CO2LineChartProps) {
  const recent = useCO2Recent(smartFarmID);

  return (
    <TableData title={"CO2 차트 (현재 : " + recent.co2 + " ppm)"}>
      <CO2LineChartBlock>
        <LineChart data={co2Data} />
      </CO2LineChartBlock>
    </TableData>
  );
}

export default CO2LineChart;
