import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import mainLogo from "../../images/main-logo.png";

interface SideBarBlockProps {
  width?: number;
  height?: number;
}

const SideBarBlock = styled.div<SideBarBlockProps>`
  width: 100%;
  height: 6%;
  padding: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 5px gray;
`;

const LogoImage = styled.img`
  padding-left: 1%;
  width: auto;
  height: 70%;
  cursor: pointer;
`;

const KakaoChannel = styled.div`
  margin-right: 0.5rem;
`;

interface SideBarProps {
  url?: string;
  children?: React.ReactNode;
}

function SideBar({ url = "/home", children }: SideBarProps) {
  const history = useHistory();

  useEffect(() => {
    const scriptKakaoSdk = document.createElement("script");
    scriptKakaoSdk.src = "https://developers.kakao.com/sdk/js/kakao.js";
    document.head.appendChild(scriptKakaoSdk);

    scriptKakaoSdk.onload = () => {
      const scriptInitKakao = document.createElement("script");
      scriptInitKakao.text = `
      Kakao.init('5dcf0989d0540d2315b1655a34cb2757');
      Kakao.Channel.createChatButton({
        container: '#create-channel-chat-button',
        channelPublicId: '_dNxkGj',
      });
    `;
      document.body.appendChild(scriptInitKakao);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <SideBarBlock>
      <LogoImage
        src={mainLogo}
        alt="상상텃밭"
        onClick={() => history.push(url)}
      />
      {children}
      <KakaoChannel id="create-channel-chat-button" />
    </SideBarBlock>
  );
}

export default SideBar;
