import { useState, useEffect } from "react";
import * as co2API from "../lib/smartFarmCo2";

function useCO2Chart(smartFarmID: number) {
  const [co2, setCo2] = useState<co2API.Co2Props[]>([]);

  const getChartData = () => {
    let now = new Date();
    let yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);

    co2API
      .getChart(smartFarmID, yesterday.toISOString(), now.toISOString())
      .then((resolvedData) => {
        const data = resolvedData;
        setCo2(data);
      });
  };

  useEffect(() => {
    getChartData();

    const interval = setInterval(getChartData, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return co2;
}

export default useCO2Chart;
