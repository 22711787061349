import { useState, useEffect } from "react";
import * as deviceAPI from "../lib/device";
import { DeviceProps } from "../data/deviceIDs";

type ConnectionProps = {
  name: string;
  connection: boolean;
};

function useConnections(deviceIDs: DeviceProps[]) {
  const [connected, setConnected] = useState<ConnectionProps[]>(
    deviceIDs.map(() => {
      return { name: "loading", connection: false };
    })
  );
  const [disconnected, setDisconnected] = useState<ConnectionProps[]>(
    deviceIDs.map(() => {
      return { name: "loading", connection: false };
    })
  );

  const promiseElement = (
    deviceID: DeviceProps,
    connections: ConnectionProps[]
  ) =>
    deviceAPI.getConnection(deviceID.id).then((resolvedData) => {
      connections.push({
        name: deviceID.name,
        connection: resolvedData,
      });
    });

  const getConnections = () => {
    let promiseArr: Promise<any>[] = [];
    let tmp: ConnectionProps[] = [];
    promiseArr = deviceIDs.map((deviceID) => promiseElement(deviceID, tmp));

    Promise.all(promiseArr).then(() => {
      let tmpDisconnected: ConnectionProps[] = [];
      let tmpConnected: ConnectionProps[] = [];
      let tmpDeviceIDs = deviceIDs.map((deviceID) => deviceID.name);

      // NOTE: 중첩 forEach 괜찮은가??
      tmpDeviceIDs.forEach((deviceID) => {
        tmp.forEach((connection) => {
          if (deviceID === connection.name) {
            if (!connection.connection) {
              tmpDisconnected.push(connection);
            } else {
              tmpConnected.push(connection);
            }
          }
        });
      });

      setConnected(tmpConnected);
      setDisconnected(tmpDisconnected);
    });
  };

  useEffect(() => {
    getConnections();

    const interval = setInterval(getConnections, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return { connected, disconnected };
}

export default useConnections;
